/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Image, SeparateLine, SeparateLineWrap, Slideshow } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Frida Kahlo"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"marginTop":0,"paddingTop":11,"backgroundColor":"#fef6f2","marginBottom":0}} name={"nwknmkrlfih"} layout={"l13"} parallax={false}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --center el--2 flex--center" style={{"paddingTop":35}} columns={"2"} anim={"2"}>
            
            <ColumnWrapper style={{"maxWidth":540}}>
              
              <Subtitle className="subtitle-box subtitle-box--left fs--24" style={{"maxWidth":541,"paddingRight":0}} content={"<span style=\"font-style: italic;\">Kabaret života</span><br>"}>
              </Subtitle>

              <Title className="title-box title-box--left" content={"Frida Kahlo"}>
              </Title>

              <Text className="text-box text-box--left lh--14" style={{"marginTop":27,"paddingRight":0,"maxWidth":542}} content={"Autor: <span style=\"font-weight: bold;\">Jakub Maceček<br></span>Texty: <span style=\"font-weight: bold;\">Michal Svatý</span><br>Hudba a aranžmá: <span style=\"font-weight: bold;\">Jiří Toufar\n</span><br>Režie: <span style=\"font-weight: bold;\">Jakub Maceček</span><br>Účinkují: <span style=\"font-weight: bold;\">Světlana Nálepková, Martin Sochor, Petr Pěknic</span>\n<br>Živě doprovází: <span style=\"font-weight: bold;\">Blue Angel Memory Band Jiřího Toufara</span><br>"}>
              </Text>

              <Text className="text-box text-box--left fs--18 lh--14" content={"Premiéra 19. dubna 2017<br>Provozovací práva: Kateřina Schauerová<br>Autorská práva k představení:&nbsp;Aura-Pont, s.r.o.<br>Délka představení: 1 hodina 55 minut s přestávkou&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":582}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37992/f4b1561333a148d2a1b96e59906d882a_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37992/f4b1561333a148d2a1b96e59906d882a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/f4b1561333a148d2a1b96e59906d882a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/f4b1561333a148d2a1b96e59906d882a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/f4b1561333a148d2a1b96e59906d882a_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"marginTop":0,"paddingTop":4,"paddingBottom":0,"backgroundColor":"#fef6f2"}} name={"fpk6ue5iee"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginBottom":0,"marginTop":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Slideshow style={{"backgroundColor":"rgba(254,236,226,1)"}} name={"xdhzme6fvy"} effect={"fade"} autoplay={true} infinite={true} transitionDuration={6}>
          
          <Column className="js-anim  --anim2" style={{"paddingTop":0,"paddingBottom":0}} anim={"2"}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/2b329b9d928442cbacb44e2ac3772d44_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/2b329b9d928442cbacb44e2ac3772d44_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/2b329b9d928442cbacb44e2ac3772d44_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/2b329b9d928442cbacb44e2ac3772d44_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/2b329b9d928442cbacb44e2ac3772d44_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/1833f8cde1de4435ac282dbd41568389_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/1833f8cde1de4435ac282dbd41568389_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/1833f8cde1de4435ac282dbd41568389_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/1833f8cde1de4435ac282dbd41568389_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/1833f8cde1de4435ac282dbd41568389_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/e317e1898eb643b4b91e26e75b344cb5_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/e317e1898eb643b4b91e26e75b344cb5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/e317e1898eb643b4b91e26e75b344cb5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/e317e1898eb643b4b91e26e75b344cb5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/e317e1898eb643b4b91e26e75b344cb5_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/6be2ce688f6c461fb0dd7ec6b6eed134_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/6be2ce688f6c461fb0dd7ec6b6eed134_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/6be2ce688f6c461fb0dd7ec6b6eed134_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/6be2ce688f6c461fb0dd7ec6b6eed134_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/6be2ce688f6c461fb0dd7ec6b6eed134_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/c45f64f0ca4741a7b0cb2eafa12c8d45_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/c45f64f0ca4741a7b0cb2eafa12c8d45_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/c45f64f0ca4741a7b0cb2eafa12c8d45_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/c45f64f0ca4741a7b0cb2eafa12c8d45_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/c45f64f0ca4741a7b0cb2eafa12c8d45_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/7f5af2748bc9440d9dd55e23c6838264_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/7f5af2748bc9440d9dd55e23c6838264_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/7f5af2748bc9440d9dd55e23c6838264_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/7f5af2748bc9440d9dd55e23c6838264_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/7f5af2748bc9440d9dd55e23c6838264_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/9777b2b1034640c4994bb2ca3c497d3b_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/9777b2b1034640c4994bb2ca3c497d3b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/9777b2b1034640c4994bb2ca3c497d3b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/9777b2b1034640c4994bb2ca3c497d3b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/9777b2b1034640c4994bb2ca3c497d3b_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/64029c62e8ab42e89ffea433ad0b20d6_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/64029c62e8ab42e89ffea433ad0b20d6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/64029c62e8ab42e89ffea433ad0b20d6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/64029c62e8ab42e89ffea433ad0b20d6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/64029c62e8ab42e89ffea433ad0b20d6_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/b596706bb9e14f46b0f1c87f265a4da6_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/b596706bb9e14f46b0f1c87f265a4da6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/b596706bb9e14f46b0f1c87f265a4da6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/b596706bb9e14f46b0f1c87f265a4da6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/b596706bb9e14f46b0f1c87f265a4da6_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/f0f4d00fabd1402b899095fbfdef572f_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/f0f4d00fabd1402b899095fbfdef572f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/f0f4d00fabd1402b899095fbfdef572f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/f0f4d00fabd1402b899095fbfdef572f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/f0f4d00fabd1402b899095fbfdef572f_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/8cae63318ae64f9b9ad05d5610160116_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/8cae63318ae64f9b9ad05d5610160116_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/8cae63318ae64f9b9ad05d5610160116_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/8cae63318ae64f9b9ad05d5610160116_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/8cae63318ae64f9b9ad05d5610160116_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Image style={{"maxWidth":542}} alt={""} src={"https://cdn.swbpg.com/t/37992/ae329b5e99b34446ab303856dc5a1606_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/37992/ae329b5e99b34446ab303856dc5a1606_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37992/ae329b5e99b34446ab303856dc5a1606_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37992/ae329b5e99b34446ab303856dc5a1606_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37992/ae329b5e99b34446ab303856dc5a1606_s=1400x_.jpg 1400w"} lightbox={true}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper style={{"maxWidth":544}}>
                
                <Text className="text-box text-box--justify fs--22" style={{"paddingLeft":0}} content={"Strhující příběh slavné mexické malířky a aktivistky. Frida během svého života prošla mnoha těžkými zkouškami, ale nikdy neztratila chut' žít dál. Její milostné eskapády s mnoha slavnými muži i ženami své doby nemohly zůstat bez odezvy veřejnosti. Společně s Fridou projdeme nejen jejím životem, ale také dějinným obdobím, které utvářelo dnešní svět.<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <SeparateLine style={{"marginTop":0,"paddingTop":4,"paddingBottom":0,"backgroundColor":"#fef6f2"}} name={"fpk6ue5iee"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginBottom":0,"marginTop":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="js-anim  --anim2 --parallax pb--60" style={{"paddingTop":51,"marginBottom":0,"backgroundColor":"#fef6f2"}} anim={"2"} name={"0xpqqy7f3q1m"} parallax={true}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ohlasy"}>
              </Title>

              <Text className="text-box text-box--center fs--18 lh--16" style={{"maxWidth":733,"marginTop":37}} content={"<span style=\"font-style: italic;\">Byli jsme totálně unešeni a nadšeni! Vším! Úžasná S. Nálepková a její kolegové, vynikající hudba i muzikanti, úžasná režie... Mimořádný zážitek!<br><br>...představení bylo plné pulzující energie, neskutečný zážitek... úžasný projev kolegů... kapela svým projevem podtrhující a dotvářející... děkuji za krásný večer...</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"marginTop":0,"paddingTop":4,"paddingBottom":0,"backgroundColor":"#fef6f2"}} name={"fpk6ue5iee"} layout={"l6"}>
          
          <SeparateLineWrap style={{"marginBottom":0,"marginTop":0}}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-ikowj1 css-42e4bw --parallax" style={{"marginBottom":0,"paddingRight":4,"paddingBottom":0}} name={"7y2ben8ii5b"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37992/343248759a1b4cefa09fd1f652f15339_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/343248759a1b4cefa09fd1f652f15339_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37992/343248759a1b4cefa09fd1f652f15339_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/343248759a1b4cefa09fd1f652f15339_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37992/343248759a1b4cefa09fd1f652f15339_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37992/343248759a1b4cefa09fd1f652f15339_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37992/343248759a1b4cefa09fd1f652f15339_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37992/343248759a1b4cefa09fd1f652f15339_s=3000x_.png);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 mt--25" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 --center" style={{"marginTop":0,"paddingTop":5,"paddingBottom":0}} name={"r8nodztltl"} anim={"2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":632,"marginTop":3,"paddingLeft":0,"marginBottom":0,"paddingRight":0,"paddingBottom":3}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":400,"paddingTop":0,"paddingLeft":0,"paddingBottom":2}}>
              
              <Title className="title-box title-box--left fs--16 w--500 ls--001 lh--14" style={{"maxWidth":450,"paddingLeft":3,"paddingRight":0}} content={"<span style=\"color: var(--color-blend--15);\">ADF | agentura divadelní fantazie<br>Kateřina Schauerová&nbsp;<br>Marek Sucharda</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper style={{"marginTop":0,"paddingTop":0,"paddingBottom":1}}>
              
              <Text className="text-box text-box--left fs--16 lh--14" style={{"maxWidth":503,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--color-custom-1--15);\"><a href=\"mailto:adf@adf.cz?subject=Zpr%C3%A1va+z+webu\">adf@adf.cz</a> | <a href=\"/\">www.adf.cz</a><br>+420 603 219 657 | <a href=\"mailto:katerina@adf.cz?subject=Zpr%C3%A1va+z+webu\">katerina@adf.cz</a><br>+420 775 262 617 | <a href=\"mailto:marek@adf.cz?subject=Zpr%C3%A1va+z+webu\">marek@adf.cz</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":2}}>
              
              <Text className="text-box text-box--center fs--12" content={"© 2024 ADF | Kateřina Schauerová, Marek Sucharda"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}